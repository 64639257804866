import React, { useEffect, useRef } from 'react';
import { string, bool } from 'prop-types';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Heading,
  Button,
  Text,
  Box,
  Image,
  Center,
  // hooks
  useDisclosure,
} from '@chakra-ui/react';

import { IMG_COVID } from '@/constants/assets';
import { BUTTON_GRADIENT, BUTTON_PROPS } from '@/constants/colors';
import txt from './locales';

/**
 * Function to render Covid Notification
 * @returns {JSX.Element | null}
 */
function CovidProtocol({ visible, lang }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isShowed = useRef(false);

  const handleCloseModal = () => {
    onClose();
  };

  useEffect(() => {
    if (visible && !isShowed.current) {
      setTimeout(() => {
        onOpen();
      }, 2000);
      isShowed.current = true;
    }
  }, []);

  const DEFAULT_BOTTOM = {
    color: 'white',
    marginTop: '12px',
    boxShadow: 'xl',
    fontSize: '14px',
    fontWeight: 'light',
    borderRadius: '6px',
    background: BUTTON_GRADIENT,
    ...BUTTON_PROPS,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width="90%" maxWidth="400px">
        <ModalBody fontSize="16px" padding="0">
          <Box textAlign="center" padding="8px 24px">
            <Heading color="alternativeDark" marginTop="16px" fontWeight="normal">
              {txt.title[lang]}
            </Heading>
            <Text fontSize="sm">{txt.subTitle[lang]}</Text>
          </Box>
          <Center>
            <Image src={IMG_COVID[lang]} width="100%" />
          </Center>
        </ModalBody>
        <ModalFooter>
          <Button
            size="sm"
            bgColor="alternativeDark"
            color="mainColorText"
            marginTop="32px"
            fontWeight="normal"
            onClick={handleCloseModal}
            {...DEFAULT_BOTTOM}
          >
            {txt.understand[lang]}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

CovidProtocol.propTypes = {
  lang: string.isRequired,
  visible: bool.isRequired,
};

export default CovidProtocol;
